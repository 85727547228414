// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_COMPANIES_URL } from "../constants"

const fetchCompanyId = () => {
  let item = window.localStorage.getItem("userData")
  if (item) {
    item = JSON.parse(item)
    if (Object.hasOwn(item, "id")) return item.id
  }
  return true
}

export const getListEnableServices = createAsyncThunk(
  "companies/getListEnableServices",
  async () => {
    const response = await axios.get(`${API_COMPANIES_URL}/list/for-enable-services/${fetchCompanyId()}`)
    return response.data
  }
)

export const getListCompanies = createAsyncThunk(
  "companies/getListCompanies",
  async ({
    page = 1,
    limit = 20,
    search,
    businessSector,
    industry,
    executive,
    accessCompany,
    ledByWoman,
    exportingCompany
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (businessSector) {
      queryParams += `&businessSector=${businessSector}`
    }
    if (industry) {
      queryParams += `&industry=${industry}`
    }
    if (executive) {
      queryParams += `&executive=${executive}`
    }
    if (accessCompany && (accessCompany === 'SI' || accessCompany === 'NO')) {
      queryParams += `&accessCompany=${accessCompany}`
    }
    if (ledByWoman && (ledByWoman === 'SI' || ledByWoman === 'NO')) {
      queryParams += `&ledByWoman=${ledByWoman}`
    }
    if (exportingCompany && (exportingCompany === 'SI' || exportingCompany === 'NO')) {
      queryParams += `&exportingCompany=${exportingCompany}`
    }
    const response = await axios.get(`${API_COMPANIES_URL}?${queryParams}`)
    return response.data
  }
)

export const attachFiles = createAsyncThunk(
  "companies/attachFiles",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    formData.append('descriptions', data.dataToSend.description)
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_COMPANIES_URL}/attachments/${data.dataToSend.companyId}/${data.dataToSend.idSupport}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const editFiles = createAsyncThunk(
  "companies/editFiles",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    formData.append('descriptions', data.dataToSend.description)
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_COMPANIES_URL}/edit-file/${data.dataToSend.companyId}/${data.dataToSend.fileId}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const updateCompany = createAsyncThunk(
  "companies/updateCompany",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_COMPANIES_URL}/${data._id}`,
      data
    )
    return response.data
  }
)

export const getCompanyById = createAsyncThunk(
  "companies/getCompanyById",
  async (id) => {
    const response = await axios.get(
      `${API_COMPANIES_URL}/${id}`
    )
    return response.data
  }
)

export const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    list: [],
    totalPages: 1,
    listEnableServices: [],
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListEnableServices.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListEnableServices.fulfilled, (state, action) => {
        state.listEnableServices = action.payload.enableServices
        state.loading = false
      })
      .addCase(getListCompanies.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListCompanies.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default companiesSlice.reducer
