import axios from "axios"
import jwtDefaultConfig from "./jwtDefaultConfig"
import { API_URL_DEV, API_URL_PROD } from "../../../constants"

// ** Third Party Components
import toast from "react-hot-toast"
import { AlertTriangle, X } from "react-feather"

// ** Custom Components
import Avatar from "@components/avatar"

const ToastServerError = ({ t, name }) => {
  return (
    <div className="d-flex">
      <div className="me-1">
        <Avatar size="md" color="danger" icon={<AlertTriangle size={16} />} />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <h6>{name}</h6>
          <X
            size={12}
            className="cursor-pointer"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
        <span>Ha ocurrido un error en el servidor...</span>
      </div>
    </div>
  )
}

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    axios.defaults.baseURL =
      process.env.NODE_ENV === "production" ? API_URL_PROD : API_URL_DEV

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          const accessTokenClean = accessToken.replace(/^"(.*)"$/, "$1")
          axios.defaults.headers.common.Authorization = `${this.jwtConfig.tokenType} ${accessTokenClean}`
          // ** eslint-disable-next-line no-param-reassign
          //config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        if (response && response.status === 500) {
          toast((t) => <ToastServerError t={t} name={"¡Ups!"} />)
        }
        return Promise.reject(error)
      }
    )
  }

  setTokenInAxios(accessToken) {
    const accessTokenClean = accessToken.replace(/^"(.*)"$/, "$1")
    axios.defaults.headers.common.Authorization = `${this.jwtConfig.tokenType} ${accessTokenClean}`
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  // For admin section
  loginAdmin(...args) {
    return axios.post(this.jwtConfig.loginEndpointAdmin, ...args)
  }

  // For user section
  loginUser(...args) {
    return axios.post(this.jwtConfig.loginEndpointUser, ...args)
  }

  // For partner section
  loginPartner(...args) {
    return axios.post(this.jwtConfig.loginEndpointPartner, ...args)
  }
}
