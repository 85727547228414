// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_BUSINESSSECTORS_URL } from "../constants"

export const getList = createAsyncThunk(
  "businessSectors/getList",
  async ({
    page = 1,
    limit = 10,
    showAll = true,
    status,
    search,
    withIndustries,
    noPaginate
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (status) {
      queryParams += `&status=${status}`
    }
    if (search) {
      queryParams += `&search=${search}`
    }
    if (withIndustries) {
      queryParams += `&withIndustries=true`
    }
    if (noPaginate) {
      queryParams += `&noPaginate=true`
    }
    const response = await axios.get(`${API_BUSINESSSECTORS_URL}?${queryParams}`)
    return response.data
  }
)

export const getListForGto = createAsyncThunk(
  "businessSectors/getListForGto",
  async ({
    page = 1,
    limit = 20,
    search
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    const response = await axios.get(`${API_BUSINESSSECTORS_URL}/for/gto-supply?${queryParams}`)
    return response.data
  }
)

export const getListBusinessSectorsSelect = createAsyncThunk(
  "businessSectors/getListBusinessSectorsSelect",
  async ({
    page = 1,
    limit = 20,
    search
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    const response = await axios.get(`${API_BUSINESSSECTORS_URL}/for/gto-supply?${queryParams}`)
    return response.data
  }
)

export const updateBusinessSector = createAsyncThunk(
  "businessSectors/updateBusinessSector",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_BUSINESSSECTORS_URL}/${data._id}`,
      data
    )
    return response.data
  }
)

export const updateImagesBusinessSector = createAsyncThunk(
  "businessSectors/updateImagesBusinessSector",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_BUSINESSSECTORS_URL}/image/${data.dataToSend.id}/${data.dataToSend.actionType}/${data.dataToSend.imageType}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const businessSectorById = createAsyncThunk(
  "businessSectors/businessSectorById",
  async (id) => {
    const response = await axios.get(
      `${API_BUSINESSSECTORS_URL}/${id}`
    )
    return response.data
  }
)

export const businessSectorsSlice = createSlice({
  name: "businessSectors",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    listForGto: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.list = action.payload.docs || action.payload
        state.totalPages = action.payload.totalPages
        state.loading = false
      })
      .addCase(getListForGto.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListForGto.fulfilled, (state, action) => {
        state.listForGto = action.payload
        state.loading = false
      })
  }
})

export default businessSectorsSlice.reducer