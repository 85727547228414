import { API_AUTH_URL, API_AUTH_ADMIN_URL, API_AUTH_USER_URL, API_AUTH_PARTNER_URL } from "../../../constants"

// ** Auth Endpoints
export default {
  loginEndpoint: API_AUTH_URL,
  registerEndpoint: "/jwt/register",
  refreshEndpoint: `${API_AUTH_URL}/refresh`,
  logoutEndpoint: "/jwt/logout",

  // This data is for admin login
  loginEndpointAdmin: API_AUTH_ADMIN_URL,
  refreshEndpoint: `${API_AUTH_ADMIN_URL}/refresh`,
  logoutEndpoint: "/jwt/logout",

  // This data is for user login
  loginEndpointUser: API_AUTH_USER_URL,
  refreshEndpoint: `${API_AUTH_USER_URL}/refresh`,
  logoutEndpoint: "/jwt/logout",

  // This data is for partner login
  loginEndpointPartner: API_AUTH_PARTNER_URL,
  refreshEndpoint: `${API_AUTH_PARTNER_URL}/refresh`,
  logoutEndpoint: "/jwt/logout",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}
