// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_AREAS_URL } from "../constants"

export const areaById = createAsyncThunk(
  "areas/areaById",
  async (id) => {
    const response = await axios.get(
      `${API_AREAS_URL}/${id}`
    )
    return response.data
  }
)

export const getListAreas = createAsyncThunk(
  "areas/getListAreas",
  async ({
    noPaginate,
    checkArchivenomy
  } = {}) => {
    let queryParams = ``
    if (noPaginate) {
      queryParams += `noPaginate=true`
    }
    if (checkArchivenomy) {
      queryParams += `&checkArchivenomy=true`
    }
    const response = await axios.get(`${API_AREAS_URL}?${queryParams}`)
    return response.data
  }
)

export const areasSlice = createSlice({
  name: "areas",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {}
})

export default areasSlice.reducer