// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_REQUESTS_URL } from "../constants"

export const getListRequests = createAsyncThunk(
  "requests/getListRequests",
  async ({
    page = 1,
    limit = 20,
    search,
    company,
    status
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    const response = await axios.get(`${API_REQUESTS_URL}/for-company/list?${queryParams}`)
    return response.data
  }
)

export const requestsSlice = createSlice({
  name: "requests",
  initialState: {
    list: [],
    loading: true,
    totalPages: 1,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListRequests.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequests.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default requestsSlice.reducer
