// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_NOTIFICATIONS_URL } from "../constants"

export const sendNotification = createAsyncThunk(
  "notifications/sendNotification",
  async (data, { dispatch, getState }) => {
    const response = await axios.post(
      `${API_NOTIFICATIONS_URL}/`,
      data
    )
    return response.data
  }
)

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    info: {}
  },
  reducers: {}
})

export default notificationsSlice.reducer
