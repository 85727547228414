export const API_URL_PROD = "https://api.cofoce.gob.mx/"
export const API_URL_DEV = "http://localhost:3001/"
export const API_BUSINESS_OPPORTUNITIES_URL = "https://gtosupply.com/"
export const API_GLOBAL_DATABASE_URL = "https://api.globaldatabase.com/v2"
export const TOKEN_GLOBAL = "3f7bdb04-2f8b-44f5-be04-9a718431434d"
export const API_AUTH_URL = "auth/company"
export const API_AUTH_ADMIN_URL = "auth/company/admin"
export const API_AUTH_USER_URL = "auth/company/user"
export const API_AUTH_PARTNER_URL = "auth/company/partner"
export const API_COMPANIES_URL = "companies"
export const API_USERS_URL = "users"
export const API_PARTNERS_URL = "partners"
export const API_REQUESTS_URL = "requests"
export const API_FINANCIAL_SUPPORT_LIST_URL = "financial-support-list"
export const API_FINANCIAL_SUPPORT_REQUESTS_URL = "financial-support-requests"
export const API_LOGINS_URL = "logins"
export const API_SERVICES_URL = "services"
export const API_NOTIFICATIONS_URL = "notifications"
export const API_DASHBOARDS_URL = "dashboards"
export const API_BUSINESSSECTORS_URL = "business-sector"
export const API_ARCHIVENOMIES_URL = "archivenomies"
export const API_ARCHIVENOMIES_YEARS_URL = "archivenomiesYears"
export const API_AREAS_URL = "areas"
export const API_INDUSTRIES_URL = "industry"
export const API_BRANCHES_URL = "branches"
export const API_SUBBRANCHES_URL = "sub-branches"
export const API_COUNTRIES_URL = "countries"
export const API_CONFIGSUPPORTS_URL = "configSupports"
export const API_SUBSERVICES_URL = "subservices"
export const API_BUYERS_URL = "buyers"
export const API_CURRENCIES_URL = "currencies"
export const API_BUYER_SUPPORTS_URL = "buyer-supports"
export const API_HISTORIES_URL = "histories"
export const API_CLASSES_URL = "classes"
export const OLD_URL_COMPANIES = 'http://cofoce.org/img/escaneos_empresas'
// IDS AREAS DATA BASE
export const INTERNATIONALIZATION = "60e7418deccf7663e409e395"
export const CAPACITATION_AND_CERTIFICATIONS = "60e7418deccf7663e409e397"
export const COMPETITIVENESS = "6145795e907c0a294eef4827"
export const DIGITAL = "60e7418deccf7663e409e396"
export const DOCUMENTS_ARRAY = [
    // GENERAL
    "requestReport",
    "requestReportBuyers",
    "noConflictsInterest",
    "resultsReport",
    "digitalStrategyFormat",
    "quote",
    "othersDocs",
    // DIGITAL AREA    
    //"commLetter",
    "generalInvoice",
    "proofOfPayment",
    "paymentComplement",
    "transactionReport",
    "transactionReceipt",
    // COMPETITIVENESS
    "freeFormatLetter",
    "serviceContract",
    "positiveResolutionLetter",
    "proofOfPaymentOptional",
    "copyRequestProcedureReport",
    "copyInternationalContract",
    "freeFormatOffice",
    // INTERNATIONALIZATION
    "buyerEvaluation",
    "businessSchedule",
    "registerSAT",
    "invoiceStand",
    "photoEvidenceStand",
    "proofOfPaymentStand",
    "documentForAssistance",
    "photoEvidenceAssistance",
    "accessDocument",
    "proofOfPaymentAssistance",
    "lodgingInvoice",
    "lodgingInvoiceXml",
    "proofOfPaymentLodging",
    "expensesPerRoom",
    "airTransportInvoice",
    "travelItinerary",
    "railwayTransportInvoice",
    "adsInvoice",
    "photoEvidenceAds",
    "proofOfPaymentAds",
    "membershipPaymentDoc",
    "proofOfPaymentMembership",
    "invoiceVirtualEvent",
    "proofOfPaymentVirtualEvent",
    "registerVirtualEvent",
    "screenshotsVirtualEvent",
    "curriculumProfile",
    "packageGuide",
    "leaseInvoice",
    "proofOfPaymentLease",
    "leasingContract",
    "leaseEvidence",
    "adequacyInvoice",
    "proofOfPaymentAdequacy",
    "adaptationContract",
    "adequacyEvidence",
    "studyCopy",
    "designCopy",
    "evidenceWork",    
    "roomsTable",
    "collaborationAgreementFinal",
    "resultsReportDigital",
    "resultsReportOptional",    
    // CERTIFICATIONS
    "proofOfAddress",
    "opinionComplianceTax",
    // EXCLUSIVE REVIEWER
    "approvementLetter",
    "invoicePaymentReceipt",
    // DEFAULT IN FINANCIAL SUPPORT MODEL
    "invoiceDoc",
    "invoiceXml",
    // GENERAL DOCUMENTS GET FOR SMART
    "bankData",
    "officialId",
    "fiscalSituationDocument",
    "constitutiveAct",
    "legalRepresentativeDocument"
]

const adsTypeOptions = [
    { value: 'radio', label: 'RADIO' },
    { value: 'televisión', label: 'TELEVISIÓN' },
    { value: 'prensa', label: 'PRENSA' },
    { value: 'plataformas digitales', label: 'PLATAFORMAS DIGITALES' }
]

const benefitImpactOptions = [
    { value: 'Presencia del Estado en el extranjero', label: 'Presencia del Estado en el extranjero' },
    { value: 'Apertura de nuestro producto para un mercado de exportación', label: 'Apertura de nuestro producto para un mercado de exportación' },
    { value: 'Oportunidades de negocio', label: 'Oportunidades de negocio' },
    { value: 'Generación de nuevos empleos', label: 'Generación de nuevos empleos' },
    { value: 'Inversión extranjera en el estado', label: 'Inversión extranjera en el estado' }
]

const digitalAdsOptions = [
    { value: 'Meta (Facebook y/o Instagram)', label: 'Meta (Facebook y/o Instagram)' },
    { value: 'Google', label: 'Google' },
    { value: 'Ambas', label: 'Ambas' },
    { value: 'Otras', label: 'Otras' }
]

export const FORM_DATA_ARRAY = [
    // INTERNATIONALIZATION
    { id: "eventName", name: "NOMBRE DEL EVENTO", type: "text" },
    { id: "cityCountry", name: "CIUDAD Y PAÍS DEL EVENTO", type: "text" },
    { id: "travelDateStart", name: "FECHA INICIO DEL VIAJE", type: "date" },
    { id: "travelDateEnd", name: "FECHA FIN DEL VIAJE", type: "date" },
    { id: "eventDateStart", name: "FECHA INICIO DEL EVENTO", type: "date" },
    { id: "eventDateEnd", name: "FECHA FIN DEL EVENTO", type: "date" },
    { id: "destinationCountry", name: "PAÍS DESTINO", type: "text" },
    { id: "periodOfService", name: "MES/PERIODO DEL SERVICIO", type: "text" },
    { id: "consultantName", name: "NOMBRE DEL CONSULTOR", type: "text" },
    { id: "shippingMonth", name: "MES(ES) DEL ENVÍO", type: "text" },
    { id: "clients", name: "CLIENTE(S)", type: "text" },
    { id: "countryOperations", name: "PAÍS DE CENTRO DE OPERACIONES", type: "text" },
    { id: "product", name: "PRODUCTO", type: "text" },
    { id: "leaseDates", name: "FECHAS DEL ARRENDAMIENTO", type: "text" },
    { id: "typeStudy", name: "TIPO DE ESTUDIO", type: "text" },
    { id: "typeAdequacy", name: "TIPO DE ADECUACIÓN", type: "text" },
    { id: "typeAdvertising", name: "TIPO DE PUBLICIDAD", type: "select", options: adsTypeOptions },
    { id: "serviceDate", name: "FECHA DEL SERVICIO", type: "text" },
    { id: "productService", name: "PRODUCTO O SERVICIO PROMOCIONADO", type: "text" },
    { id: "buyerCompanyName", name: "NOMBRE(S) DE LA(S) EMPRESA(S) COMPRADORA(S)", type: "text" },
    { id: "buyerName", name: "NOMBRE DEL O LOS COMPRADORES", type: "text" },
    { id: "scheduleDate", name: "FECHA DE AGENDA", type: "text" },
    { id: "countryResearch", name: "PAÍS DESTINO DONDE SE ENFOCA EL ESTUDIO", type: "text" },
    // DIGITAL
    { id: "digitalAdvertising", name: "HERRAMIENTAS DE PUBLICIDAD", type: "select", options: digitalAdsOptions },
    // COMPETTITIVENESS
    { id: "fiscalNameSupplier", name: "RAZÓN SOCIAL", type: "text" },
    { id: "tradeNameSupplier", name: "NOMBRE COMERCIAL", type: "text" },
    // CERTIFICATIONS
    { id: "jobsTestsConsultancies", name: "INDICAR LOS TRABAJOS EN MATERIA DE CERTIFICACIONES, PRUEBAS O CONSULTORIA QUE SE REALIZARAN", type: "textarea" },
    { id: "serviceCost", name: "COSTO SERVICIO IVA INCLUIDO", type: "number" },
    { id: "serviceSupplier", name: "PROVEEDOR DEL SERVICIO", type: "text" },
    { id: "countryProducts", name: "PAÍS DESTINO DE PRODUCTOS", type: "text" },
    { id: "legislation", name: "INDICAR LEGISLACIÓN O CUMPLIMIENTO", type: "text" },
    { id: "benefitImpact", name: "BENEFICIO ECONÓMICO E IMPACTO EN VENTAS", type: "select", multiselect: true, options: benefitImpactOptions },
    { id: "growthRate", name: "PORCENTAJE CRECIMIENTO VENTAS TOTALES", type: "number" },
    { id: "salesRate", name: "PORCENTAJE INCREMENTO VENTAS NACIONAL", type: "number" },
    { id: "exportsRate", name: "PORCENTAJE CRECIMIENTO EXPORTACIÓN", type: "number" },
    // CAPACITATION
    { id: "capacitationConcept", name: "CONCEPTO DE LA CAPACITACIÓN", type: "text" },
    { id: "capacitationLocation", name: "LUGAR(ES) DE LA CAPACITACIÓN", type: "text" },
    { id: "capacitationDates", name: "FECHA DEL EVENTO (INCLUYENDO EL AÑO)", type: "text" },
    { id: "capacitationCost", name: "INDICAR COSTO SIN IVA (PESOS MEXICANOS)", type: "number" },
    { id: "nationalSalesRate", name: "PORCENTAJE CRECIMIENTO VENTAS NACIONAL", type: "number" },
    { id: "exportSalesRate", name: "PORCENTAJE CRECIMIENTO EXPORTACIÓN", type: "number" },
    { id: "exportAmount", name: "MONTO DE EXPORTACIÓN EN USD (CIERRE DE OPERACIONES DEL 2020)", type: "number" }
]

export const DOCUMENTS_GENERAL_ARRAY = [
    "officialId",
    "fiscalSituationDocument",
    "constitutiveAct",
    "legalRepresentativeDocument",
    "bankData"
]

export const SERVICES_ONE_EXTRA_STEP = [
    "614b5b2496168a9ae7318e69",
    "614aaf6a19ad1834e058fdc9",
    "614aaf6a19ad1834e058fdca",
    "64766fb438a8011f0740b306",
    "614aaf6a19ad1834e058fda0"
]

export const DOCUMENTS_ARRAY_ARCHIVENOMY = [
    // GENERAL
    "requestReport",
    "requestReportBuyers",
    "noConflictsInterest",
    "resultsReport",
    "digitalStrategyFormat",
    "quote",
    "othersDocs",
    // DIGITAL AREA    
    "commLetter",
    "generalInvoice",
    "proofOfPayment",
    "paymentComplement",
    "transactionReport",
    "transactionReceipt",
    // COMPETITIVENESS
    "freeFormatLetter",
    "serviceContract",
    "positiveResolutionLetter",
    "proofOfPaymentOptional",
    "copyRequestProcedureReport",
    "copyInternationalContract",
    "freeFormatOffice",
    // INTERNATIONALIZATION
    "buyerEvaluation",
    "businessSchedule",
    "registerSAT",
    "invoiceStand",
    "photoEvidenceStand",
    "proofOfPaymentStand",
    "documentForAssistance",
    "photoEvidenceAssistance",
    "accessDocument",
    "proofOfPaymentAssistance",
    "lodgingInvoice",
    "lodgingInvoiceXml",
    "proofOfPaymentLodging",
    "expensesPerRoom",
    "airTransportInvoice",
    "travelItinerary",
    "railwayTransportInvoice",
    "adsInvoice",
    "photoEvidenceAds",
    "proofOfPaymentAds",
    "membershipPaymentDoc",
    "proofOfPaymentMembership",
    "invoiceVirtualEvent",
    "proofOfPaymentVirtualEvent",
    "registerVirtualEvent",
    "screenshotsVirtualEvent",
    "curriculumProfile",
    "packageGuide",
    "leaseInvoice",
    "proofOfPaymentLease",
    "leasingContract",
    "leaseEvidence",
    "adequacyInvoice",
    "proofOfPaymentAdequacy",
    "adaptationContract",
    "adequacyEvidence",
    "studyCopy",
    "designCopy",
    "evidenceWork",    
    "roomsTable",
    "collaborationAgreementFinal",
    "resultsReportDigital",
    "resultsReportOptional",    
    // GENERAL DOCUMENTS GET FOR SMART
    "officialId",
    "fiscalSituationDocument",
    "constitutiveAct",
    "legalRepresentativeDocument",
    "approvementLetter",
    // DEFAULT IN FINANCIAL SUPPORT MODEL
    "invoiceDoc",
    "invoiceXml",
    "bankData",
    // CERTIFICATIONS
    "proofOfAddress",
    "opinionComplianceTax",
    // EXCLUSIVE REVIEWER    
    "invoicePaymentReceipt"    
]

export const DOCUMENTS_ARRAY_REDUCE = [
    "requestReport",
    "buyerEvaluation",
    "businessSchedule",
    "travelItinerary",
    "othersDocs"
]