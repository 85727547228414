// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_SUBBRANCHES_URL } from "../constants"

export const updateImagesSubBranches = createAsyncThunk(
  "subBranches/updateImagesSubBranches",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_SUBBRANCHES_URL}/image/${data.dataToSend.id}/${data.dataToSend.actionType}/${data.dataToSend.imageType}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const subBranchesSlice = createSlice({
  name: "subBranches",
  initialState: {
    listForGto: []
  },
  reducers: {}
})

export default subBranchesSlice.reducer