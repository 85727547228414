// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_BUYERS_URL } from "../constants"

export const getListBuyers = createAsyncThunk(
  "buyers/getListBuyers",
  async ({
    page = 1,
    limit = 20,
    search
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    const response = await axios.get(`${API_BUYERS_URL}?${queryParams}`)
    return response.data
  }
)

export const getListBuyersSelect = createAsyncThunk(
  "buyers/getListBuyers",
  async ({
    page = 1,
    limit = 20,
    search
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    const response = await axios.get(`${API_BUYERS_URL}?${queryParams}`)
    return response.data
  }
)

export const buyersSlice = createSlice({
  name: "buyers",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListBuyers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListBuyers.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default buyersSlice.reducer
