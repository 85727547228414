// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_CONFIGSUPPORTS_URL } from "../constants"

export const configSupportsByModality = createAsyncThunk(
  "configSupports/configSupportByModality",
  async (modality) => {
    const response = await axios.get(
      `${API_CONFIGSUPPORTS_URL}/modality/${modality}`
    )
    return response.data
  }
)

export const configSupportsSingleBuyer = createAsyncThunk(
  "configSupports/configSupportsSingleBuyer",
  async (modality) => {
    const response = await axios.get(
      `${API_CONFIGSUPPORTS_URL}/single-buyer/${modality}`
    )
    return response.data
  }
)

export const configSupportsSlice = createSlice({
  name: "configSupports",
  initialState: {},
  reducers: {}
})

export default configSupportsSlice.reducer