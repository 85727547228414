// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_CURRENCIES_URL } from "../constants"

export const currencyById = createAsyncThunk(
  "currencies/currencyById",
  async (id) => {
    const response = await axios.get(
      `${API_CURRENCIES_URL}/${id}`
    )
    return response.data
  }
)

export const getListCurrencies = createAsyncThunk(
  "currencies/getListCurrencies",
  async ({
    noPaginate
  } = {}) => {
    let queryParams = ``
    if (noPaginate) {
      queryParams += `noPaginate=true`
    }
    const response = await axios.get(`${API_CURRENCIES_URL}?${queryParams}`)
    return response.data
  }
)

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {}
})

export default currenciesSlice.reducer