// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_COMPANIES_URL, API_USERS_URL, API_PARTNERS_URL } from "../../../constants"

const fetchId = () => {
  let item = window.localStorage.getItem("userData")
  if (item) {
    item = JSON.parse(item)
    if (Object.hasOwn(item, "id")) return {id: item.id, role: item.role, rfc: item.rfc}
  }
  return true
}

export const checkEmailExists = createAsyncThunk(
  "profile/checkEmailExists",
  async (email) => {
    // First verify that the email does not exist
    const response = await axios.get(
      `${API_COMPANIES_URL}/check-email/${email}`
    )
    return response.data
  }
)

export const generateCredentials = createAsyncThunk(
  "profile/generateCredentials",
  async (data, { dispatch, getState }) => {
    if (!data.companyId) {
      const { id } = fetchId()
      // Set companyId into body data
      data.companyId = id
    }
    const response = await axios.put(
      `${API_COMPANIES_URL}/generate/credentials`,
      data
    )
    return response.data
  }
)

export const updateCredentials = createAsyncThunk(
  "profile/updateCredentials",
  async (data, { dispatch, getState }) => {
    if (!data.companyId) {
      const { id } = fetchId()
      // Set companyId into body data
      data.companyId = id
    }
    const response = await axios.put(
      `${API_COMPANIES_URL}/update/credentials`,
      data
    )
    return response.data
  }
)

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (profile, { dispatch, getState }) => {
    let { id } = fetchId()
    if (profile.reviewer) {
      id = profile._id
    }    
    // Set a value for conditional update company since company project and not from SMART project
    profile.isFromCompanyProject = true
    const response = await axios.put(
      `${API_COMPANIES_URL}/${id}`,
      profile
    )
    return response.data
  }
)

export const uploadLogo = createAsyncThunk(
  "profile/uploadLogo",
  async (data, { dispatch, getState }) => {

    const isEditLogo = data.dataToSend.isEdit
    const formData = new FormData()
    formData.append('files', data.file[0])

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_COMPANIES_URL}/logo/${data.dataToSend.companyId}/${isEditLogo ? 'edit' : 'new'}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const removeLogo = createAsyncThunk(
  "profile/removeLogo",
  async (data, { dispatch, getState }) => {

    const response = await axios.delete(
      `${API_COMPANIES_URL}/remove-logo/${data.companyId}`
    )
    return response.data
  }
)

export const getProfileById = createAsyncThunk(
  "profile/getProfileById",
  async () => {
    const { id } = fetchId()
    const response = await axios.get(
      `${API_COMPANIES_URL}/${id}`
    )
    return response.data
  }
)

export const uploadBanner = createAsyncThunk(
  "profile/uploadBanner",
  async (data, { dispatch, getState }) => {

    const isEdit = data.dataToSend.isEdit
    const formData = new FormData()
    formData.append('files', data.file)

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_COMPANIES_URL}/banner/${data.dataToSend.companyId}/${isEdit ? 'edit' : 'new'}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const uploadGtoDocs = createAsyncThunk(
  "profile/uploadGtoDocs",
  async (data, { dispatch, getState }) => {

    const isEdit = data.dataToSend.isEdit
    const field = data.dataToSend.field
    const formData = new FormData()
    formData.append('files', data.file[0])

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_COMPANIES_URL}/docs/${data.dataToSend.companyId}/${isEdit ? 'edit' : 'new'}/${field}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

const initialInfo = {
  tradeName: "",
  fiscalName: "",
  rfc: "",
  registrationDate: null,
  addresses: [],
  typeFiscalPerson: "",
  sizeCompany: "",
  giro: "",
  isExportingCompany: true,
  businessSector: null,
  industry: null,
  branches: [],
  subBranches: [],
  classes: [],
  businessActivity: "",
  contacts: [],
  enableSupport: false,
  attachments: [],
  // FOR USER DATA AS ADMIN
  typeUser: "",
  fullName: "",
  emailUser: "",
  supports: false,
  businessSectors: []
}

const initialContactInfo = {
  name: "",
  emails: [],
  phones: [],
  positions: []
}

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    info: initialInfo,
    contactSelected: initialContactInfo
  },
  reducers: {
    setProfile: (state, action) => {
      state.info = action.payload
    },
    setContactSelected: (state, action) => {
      state.contactSelected = action.payload
    }
  }
})

export const { setProfile, setContactSelected } = profileSlice.actions

export default profileSlice.reducer

export const getProfileData = () => (dispatch) => {
  const { id, role } = fetchId()
  let apiToRead = API_USERS_URL // roles: admin, superadmin, user
  const typeUser = role
  switch (role) {
    case 'company':
      apiToRead = API_COMPANIES_URL
      break
      case 'partner':
      apiToRead = API_PARTNERS_URL
      break
  }
  
  axios
    .get(`${apiToRead}/${id}`)
    .then((response) => {
      dispatch(setProfile({...response.data, typeUser}))
    })
    .catch((error) => console.log(error)) 
}
