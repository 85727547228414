// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_BUSINESS_OPPORTUNITIES_URL } from "../constants"

const fetchCompanyId = () => {
  let item = window.localStorage.getItem("userData")
  if (item) {
    item = JSON.parse(item)
    if (Object.hasOwn(item, "id")) return item.id
  }
  return true
}

export const getListBusinessOpportunities = createAsyncThunk(
  "businessOpportunities/getListBusinessOpportunities",
  async ({
    page = 1,
    limit = 20,
    search,
    status,
    sector,
    subsector
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    if (sector) {
      queryParams += `&sector=${sector}`
    }
    if (subsector) {
      queryParams += `&subsector=${subsector}`
    }
    const response = await axios.get(`${API_BUSINESS_OPPORTUNITIES_URL}api/requirements/${fetchCompanyId()}/listado/?${queryParams}`)
    return response.data
  }
)

export const updatetBusinessOpportunity = createAsyncThunk(
  "businessOpportunities/updatetBusinessOpportunity",
  async (data, { dispatch, getState }) => {
    const formData = new FormData()
    formData.append('id_requirement', data.id_requirement)
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const response = await axios.post(
      `${API_BUSINESS_OPPORTUNITIES_URL}api/requirements/${fetchCompanyId()}/postulaciones/`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const getListBusinessOpportunitiesApplications = createAsyncThunk(
  "businessOpportunities/getListBusinessOpportunitiesApplications",
  async ({
    page = 1,
    limit = 20,
    search,
    status,
    sector,
    subsector
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    if (sector) {
      queryParams += `&sector=${sector}`
    }
    if (subsector) {
      queryParams += `&subsector=${subsector}`
    }
    const response = await axios.get(`${API_BUSINESS_OPPORTUNITIES_URL}api/requirements/${fetchCompanyId()}/company/?${queryParams}`)
    return response.data
  }
)

export const getBusinessOpportunitiesByFolio = createAsyncThunk(
  "businessOpportunities/getBusinessOpportunitiesByFolio",
  async (folio) => {
    const response = await axios.get(`${API_BUSINESS_OPPORTUNITIES_URL}api/requirements/${folio}/folio/`)
    return response.data
  }
)

const addTimeToDate = (date, type) => {
  const year = parseInt(date.substr(0, 4))
  const month = parseInt(date.substr(5, 2)) - 1
  const day = parseInt(date.substr(8, 2))

  let dateFormat = new Date(year, month, day, 0, 0, 0)

  if (type === 'end') {
    dateFormat = new Date(year, month, day, 23, 59, 59)
  }

  return dateFormat
}

export const businessOpportunitiesSlice = createSlice({
  name: "businessOpportunities",
  initialState: {
    list: [],
    listApplications: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListBusinessOpportunities.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListBusinessOpportunities.fulfilled, (state, action) => {
        const list = action.payload.results || action.payload
        const newList = []
        const today = new Date()
        if (list && list.length > 0) {
          list.map((el) => {
            const endDateFormat = addTimeToDate(el.end_date, "end")
            if (today > endDateFormat) {
              if (el.status === 'SERVED' || el.status === 'CANCEL') {
                //
              } else {
                el.status = 'EXPIRED'
              }
            }
            newList.push(el)
          })
        }
        const page = parseInt(action.payload.page)
        if (page && page > 1) {
          const limit = parseInt(action.payload.limit)          
          const realPage = page - 1
          const pagingCounter = (limit * realPage) + 1
          state.pagingCounter = pagingCounter
        } else {
          state.pagingCounter = action.payload.page
        }
        state.list = newList
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListBusinessOpportunitiesApplications.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListBusinessOpportunitiesApplications.fulfilled, (state, action) => {
        const listApplications = action.payload.results || action.payload
        const newList = []
        const today = new Date()
        if (listApplications && listApplications.length > 0) {
          listApplications.map((el) => {
            const endDateFormat = addTimeToDate(el.end_date, "end")
            if (today > endDateFormat) {
              if (el.status === 'SERVED' || el.status === 'CANCEL') {
                //
              } else {
                el.status = 'EXPIRED'
              }
            }
            // CHECK IF COMPANY INCLUDE IN ARRAY APPLICATIONS DATA
            if (el.status !== 'EXPIRED') {
              const companyId = fetchCompanyId()
              el.companies.map((c) => {
                if ((c.company.object_id === companyId) && c.short_list && el.status !== 'SERVED') {
                  el.status = 'SHORTSELECTED'
                }
                if ((c.company.object_id === companyId) && c.short_list && el.status === 'SERVED') {
                  el.status = 'SERVEDLINKED'
                }
              })
            }

            newList.push(el)
          })
        }
        const page = parseInt(action.payload.page)
        if (page && page > 1) {
          const limit = parseInt(action.payload.limit)          
          const realPage = page - 1
          const pagingCounter = (limit * realPage) + 1
          state.pagingCounter = pagingCounter
        } else {
          state.pagingCounter = action.payload.page
        }
        state.listApplications = newList
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default businessOpportunitiesSlice.reducer
