// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_ARCHIVENOMIES_YEARS_URL } from "../constants"

export const getListArchivenomiesYears = createAsyncThunk(
  "archivenomiesYears/getListArchivenomiesYears",
  async ({
    page = 1,
    limit = 20,
    search,
    area,
    noPaginate
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (area) {
      queryParams += `&area=${area}`
    }
    if (noPaginate) {
      queryParams += `&noPaginate=true`
    }
    const response = await axios.get(`${API_ARCHIVENOMIES_YEARS_URL}?${queryParams}`)
    return response.data
  }
)

export const storeArchivenomyYears = createAsyncThunk(
  "archivenomiesYears/storeArchivenomyYears",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_ARCHIVENOMIES_YEARS_URL}`,
      data
    )
    return response.data
  }
)

export const archivenomiesYearsSlice = createSlice({
  name: "archivenomiesYears",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {}
  /*extraReducers: (builder) => {
    builder
      .addCase(getListArchivenomies.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListArchivenomies.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }*/
})

export default archivenomiesYearsSlice.reducer