// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_PARTNERS_URL } from "../constants"

export const getListPartners = createAsyncThunk(
  "partners/getListPartners",
  async ({
    page = 1,
    limit = 10,
    search
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }

    const response = await axios.get(`${API_PARTNERS_URL}?${queryParams}`)
    return response.data
  }
)

export const updatePartner = createAsyncThunk(
  "partners/updatePartner",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_PARTNERS_URL}/${data._id}`,
      data
    )
    return response.data
  }
)

export const partnersSlice = createSlice({
  name: "partners",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListPartners.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListPartners.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default partnersSlice.reducer
