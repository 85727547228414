// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_SERVICES_URL } from "../constants"

export const getListForSupport = createAsyncThunk(
  "services/getListForSupport",
  async ({
    page = 1,
    limit = 50,
    noPaginate,
    buyer
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (noPaginate) {
      queryParams += `&noPaginate=true`
    }
    if (buyer) {
      queryParams += `&buyer=true`
    }
    const response = await axios.get(`${API_SERVICES_URL}/list/forSupport?${queryParams}`)
    console.log(`RESPONSE services:::`, response)
    return response.data
  }
)

export const getListServices = createAsyncThunk(
  "services/getListServices",
  async ({
    page = 1,
    limit = 20,
    search,
    isForCompany,
    status
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (isForCompany) {
      queryParams += `&isForCompany=${isForCompany}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    const response = await axios.get(`${API_SERVICES_URL}?${queryParams}`)
    return response.data
  }
)

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    list: [],
    listForSupport: [],
    loading: true,
    totalPages: 1,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListForSupport.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListForSupport.fulfilled, (state, action) => {
        state.listForSupport = action.payload
        state.loading = false
      })
      .addCase(getListServices.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListServices.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default servicesSlice.reducer
