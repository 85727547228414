// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_ARCHIVENOMIES_URL } from "../constants"

export const archivenomyById = createAsyncThunk(
  "archivenomies/archivenomyById",
  async (id) => {
    const response = await axios.get(
      `${API_ARCHIVENOMIES_URL}/${id}`
    )
    return response.data
  }
)

export const archivenomySize = createAsyncThunk(
  "archivenomies/archivenomySize",
  async (data) => {
    const response = await axios.post(
      `${API_ARCHIVENOMIES_URL}/get/size`,
      data
    )
    return response.data
  }
)

export const syncFinishArchivenomy = createAsyncThunk(
  "archivenomies/syncFinishArchivenomy",
  async ({
    area,
    year
  } = {}) => {
    const queryParams = `area=${area}&year=${year}`
    const response = await axios.get(
      `${API_ARCHIVENOMIES_URL}/sync/finished?${queryParams}`
    )
    return response.data
  }
)

export const getListArchivenomies = createAsyncThunk(
  "archivenomies/getListArchivenomies",
  async ({
    page = 1,
    limit = 20,
    search,
    status,
    area,
    year
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    if (area) {
      queryParams += `&area=${area}`
    }
    if (year) {
      queryParams += `&year=${year}`
    }
    const response = await axios.get(`${API_ARCHIVENOMIES_URL}?${queryParams}`)
    return response.data
  }
)

export const getInventoryArchivenomies = createAsyncThunk(
  "archivenomies/getInventoryArchivenomies",
  async ({
    area,
    year
  } = {}) => {
    let queryParams = ''
    if (area) {
      queryParams += `area=${area}`
    }
    if (year) {
      queryParams += `&year=${year}`
    }
    const response = await axios.get(`${API_ARCHIVENOMIES_URL}/inventory/report?${queryParams}`)
    return response.data
  }
)

export const archivenomiesSlice = createSlice({
  name: "archivenomies",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListArchivenomies.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListArchivenomies.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default archivenomiesSlice.reducer