// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_USERS_URL } from "../constants"

export const getListExecutives = createAsyncThunk(
  "executives/getListExecutives",
  async ({
    page = 1,
    limit = 10,
    search
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }

    const response = await axios.get(`${API_USERS_URL}?${queryParams}`)
    return response.data
  }
)

export const notificationCompany = createAsyncThunk(
  "executives/notificationCompany",
  async (data, { dispatch, getState }) => {
    const response = await axios.post(
      `${API_USERS_URL}/notifications/company`,
      data
    )
    return response.data
  }
)

export const executivesSlice = createSlice({
  name: "executives",
  initialState: {},
  reducers: {}
})

export default executivesSlice.reducer
