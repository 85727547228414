// ** React Imports
import { useEffect, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import { unwrapResult } from "@reduxjs/toolkit"

// ** Custom Hooks
import useJwt from "@src/auth/jwt/useJwt"

// ** Third Party Components
import toast from "react-hot-toast"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useForm, Controller } from "react-hook-form"
import { Coffee, X, AlertTriangle } from "react-feather"

// ** Store & Actions
import {
  setProfile,
  checkEmailExists,
  generateCredentials,
  updateCredentials
} from "@src/views/profile/store"
import { handleLogin } from "@store/authentication"
import { useDispatch, useSelector } from "react-redux"

// ** Context
import { AbilityContext } from "@src/utility/context/Can"

// ** Custom Components
import Avatar from "@components/avatar"
import InputPasswordToggle from "@components/input-password-toggle"

// ** Utils
import { getHomeRouteForLoggedInUser } from "@utils"

// ** Reactstrap Imports
import { Form, Label, Input, Button, Alert, Spinner } from "reactstrap"

// ** Styles
import "@styles/react/pages/page-authentication.scss"

const MySwal = withReactContent(Swal)

// ** Message showing after login success
const ToastContent = ({ t, name }) => {
  return (
    <div className="d-flex">
      <div className="me-1">
        <Avatar size="sm" color="success" icon={<Coffee size={12} />} />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <h6>¡Bienvenido!</h6>
          <X
            size={12}
            className="cursor-pointer"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
        <span>{name}</span>
      </div>
    </div>
  )
}

const defaultValues = {
  email: "",
  password: "",
  passwordConfirm: ""
}

const FormCredentials = ({ section, userData, close }) => {
  // ** States
  const [visibleMessageError, setVisibleMessageError] = useState({
    visible: false,
    text: ""
  })
  const [executingSubmit, setExecutingSubmit] = useState(false)
  const store = useSelector((state) => state.auth)

  // If section update-credentials, set currentPassword to validate form
  if (section === "update-credentials") {
    defaultValues.email = userData.email
    defaultValues["currentPassword"] = ""
  }

  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const {
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  //** ComponentDidMount
  useEffect(() => {
    if (section === "recovery-credentials") {
      setValue("email", store.recoveryData.email)
    }
  }, [store])

  const validatePasswords = (data) => {
    if (data.password !== data.passwordConfirm) return false
    return true
  }

  const validateForm = (data) => {
    if (data.email.trim() === "") return false
    if (data.password.trim() === "") return false
    if (data.passwordConfirm.trim() === "") return false
    if (section === "update-credentials" && data.currentPassword.trim() === "") return false
    return true
  }

  const updateCredentialsFn = (data) => {
    dispatch(updateCredentials(data))
      .then(unwrapResult)
      .then((res) => {
        if (res) {
          dispatch(setProfile({ ...userData, email: data.email }))
          setExecutingSubmit(false)
          MySwal.fire({
            icon: "success",
            title: "¡Actualizado!",
            text: "Todos los datos han sido actualizados con éxito",
            customClass: {
              confirmButton: "btn btn-success"
            }
          }).then(function (result) {
            close()
          })
        }
      })
      .catch((err) => {
        setExecutingSubmit(false)
        setVisibleMessageError({
          visible: true,
          text: "¡La contraseña actual es incorrecta!"
        })
      })
  }

  const handleUpdateCredentials = (data) => {
    // If the email is going to be updated
    if (userData.email !== data.email) {
      dispatch(checkEmailExists(data.email))
        .then(unwrapResult)
        .then((res) => {
          if (res) {
            if (!res.exists) {
              updateCredentialsFn(data)
            } else {
              setExecutingSubmit(false)
              setVisibleMessageError({
                visible: true,
                text: "¡Este email ya ha sido registrado, intenta con uno diferente!"
              })
            }
          }
        })
        .catch((err) => {
          setExecutingSubmit(false)
          // Mensaje de error
        })
    } else {
      // The email is not changed
      updateCredentialsFn(data)
    }
  }

  const handleGenerateCredentials = (data) => {
    dispatch(checkEmailExists(data.email))
      .then(unwrapResult)
      .then((res) => {
        if (res) {
          if (!res.exists) {
            dispatch(generateCredentials(data))
              .then(unwrapResult)
              .then((res) => {
                if (res) {
                  useJwt
                    .login({ rfc: res.rfc, password: data.password })
                    .then((res) => {
                      const data = {
                        ...res.data.userData,
                        accessToken: res.data.accessToken,
                        refreshToken: res.data.refreshToken
                      }
                      dispatch(handleLogin(data))
                      ability.update(res.data.userData.ability)
                      navigate(getHomeRouteForLoggedInUser(data.role))
                      const token = useJwt.getToken()
                      if (token) {
                        useJwt.setTokenInAxios(token)
                      }
                      toast((t) => (
                        <ToastContent
                          t={t}
                          name={data.tradeName || "Empresa"}
                        />
                      ))
                    })
                    .catch((err) => {
                      setExecutingSubmit(false)
                      // Mensaje de error
                    })
                }
              })
              .catch((err) => {
                setExecutingSubmit(false)
                // Mensaje de error
              })
          } else {
            setExecutingSubmit(false)
            setVisibleMessageError({
              visible: true,
              text: "¡Este email ya ha sido registrado, intenta con uno diferente!"
            })
          }
        }
      })
      .catch((err) => {
        setExecutingSubmit(false)
        // Mensaje de error
      })
  }

  const handleRecoveryCredentials = (data) => {
    // Add companyId from data to sent
    data["companyId"] = store.recoveryData.companyId
    dispatch(generateCredentials(data))
      .then(unwrapResult)
      .then((res) => {
        if (res) {
          useJwt
            .login({ rfc: res.rfc, password: data.password })
            .then((res) => {
              const data = {
                ...res.data.userData,
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken
              }
              dispatch(handleLogin(data))
              ability.update(res.data.userData.ability)
              navigate(getHomeRouteForLoggedInUser(data.role))
              const token = useJwt.getToken()
              if (token) {
                useJwt.setTokenInAxios(token)
              }
              toast((t) => (
                <ToastContent t={t} name={data.tradeName || "Empresa"} />
              ))
            })
            .catch((err) => {
              setExecutingSubmit(false)
              // Mensaje de error
            })
        }
      })
      .catch((err) => {
        setExecutingSubmit(false)
        // Mensaje de error
      })
  }

  const onSubmit = (data) => {
    if (Object.values(data).every((field) => field.length > 0)) {
      if (validateForm(data)) {
        if (validatePasswords(data)) {
          setExecutingSubmit(true)
          if (section === "update-credentials") {
            handleUpdateCredentials(data)
          } else if (section === "recovery-credentials") {
            handleRecoveryCredentials(data)
          } else {
            handleGenerateCredentials(data)
          }
        } else {
          setVisibleMessageError({
            visible: true,
            text: "¡Las nuevas contraseñas no coinciden!"
          })
        }
      } else {
        setVisibleMessageError({
          visible: true,
          text: "Completa los campos obligatorios"
        })
      }
    } else {
      for (const key in data) {
        if (data[key].length === 0) {
          setError(key, {
            type: "manual"
          })
        }
      }
    }
  }

  return (
    <Form className="auth-login-form mt-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-1">
        <Label className="form-label" for="email">
          Correo electrónico <span className="text-danger">*</span>
        </Label>
        <Controller
          id="email"
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              disabled={section === "recovery-credentials"}
              autoFocus
              type="email"
              placeholder="email@example.com"
              invalid={errors.email && true}
              {...field}
            />
          )}
        />
      </div>

      {section && section === "update-credentials" ? (
        <div className="mb-1">
          <div className="d-flex justify-content-between">
            <Label className="form-label" for="currentPassword">
              Contraseña actual <span className="text-danger">*</span>
            </Label>
          </div>
          <Controller
            id="currentPassword"
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <InputPasswordToggle
                className="input-group-merge"
                invalid={errors.currentPassword && true}
                {...field}
              />
            )}
          />
        </div>
      ) : null}

      <div className="mb-1">
        <div className="d-flex justify-content-between">
          <Label className="form-label" for="password">
            Nueva contraseña <span className="text-danger">*</span>
          </Label>
        </div>
        <Controller
          id="password"
          name="password"
          control={control}
          render={({ field }) => (
            <InputPasswordToggle
              className="input-group-merge"
              invalid={errors.password && true}
              {...field}
            />
          )}
        />
      </div>
      <div className="mb-1">
        <div className="d-flex justify-content-between">
          <Label className="form-label" for="passwordConfirm">
            Confirmar nueva contraseña <span className="text-danger">*</span>
          </Label>
        </div>
        <Controller
          id="passwordConfirm"
          name="passwordConfirm"
          control={control}
          render={({ field }) => (
            <InputPasswordToggle
              className="input-group-merge"
              invalid={errors.passwordConfirm && true}
              {...field}
            />
          )}
        />
      </div>
      <Button type="submit" color="primary" block disabled={executingSubmit}>
        {executingSubmit ? (
          <Spinner color="white" size="sm" />
        ) : (
          <span>Guardar cambios</span>
        )}
      </Button>
      <Alert
        color="danger"
        className="mt-2 text-center"
        isOpen={visibleMessageError.visible}
      >
        <div className="alert-body">
          <AlertTriangle size={15} />
          <span className="ms-1">{visibleMessageError.text}</span>
        </div>
      </Alert>
    </Form>
  )
}

export default FormCredentials
