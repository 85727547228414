// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_SUBSERVICES_URL } from "../constants"

export const subservicesByModality = createAsyncThunk(
  "subservices/subservicesByModality",
  async (modality) => {
    const response = await axios.get(
      `${API_SUBSERVICES_URL}/modality/${modality}`
    )
    return response.data
  }
)

export const subservicesSlice = createSlice({
  name: "subservices",
  initialState: {},
  reducers: {}
})

export default subservicesSlice.reducer