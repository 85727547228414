// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_COUNTRIES_URL } from "../constants"

export const getListCountries = createAsyncThunk(
  "countries/getListCountries",
  async ({
    noPaginate
  } = {}) => {
    let queryParams = ``
    if (noPaginate) {
      queryParams += `noPaginate=true`
    }
    const response = await axios.get(`${API_COUNTRIES_URL}?${queryParams}`)
    return response.data
  }
)

export const getListCountriesFull = createAsyncThunk(
  "countries/getListCountriesFull",
  async ({
    noPaginate
  } = {}) => {
    let queryParams = ``
    if (noPaginate) {
      queryParams += `noPaginate=true`
    }
    const response = await axios.get(`${API_COUNTRIES_URL}/full?${queryParams}`)
    return response.data
  }
)

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {}
})

export default countriesSlice.reducer