// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_DASHBOARDS_URL } from "../constants"

export const getList = createAsyncThunk(
  "dashboards/getList",
  async ({
    page = 1,
    limit = 10,
    showAll = true,
    status,
    search,
    noPaginate,
    type
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`   
    if (status) {
      queryParams += `&status=${status}`
    }
    if (search) {
      queryParams += `&search=${search}`
    }
    if (type) {
      queryParams += `&type=${type}`
    }
    if (noPaginate) {
      queryParams += `&noPaginate=${noPaginate}`
    }
    const response = await axios.get(`${API_DASHBOARDS_URL}?${queryParams}`)
    return response.data
  }
)

export const updateDashboard = createAsyncThunk(
  "dashboards/updateDashboard",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_DASHBOARDS_URL}/${data._id}`,
      data
    )
    return response.data
  }
)

export const dashboardById = createAsyncThunk(
  "dashboards/dashboardById",
  async (id) => {
    const response = await axios.get(
      `${API_DASHBOARDS_URL}/${id}`
    )
    return response.data
  }
)

export const storeDashboard = createAsyncThunk(
  "dashboards/storeDashboard",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_DASHBOARDS_URL}`,
      data
    )
    return response.data
  }
)

export const removeDashboard = createAsyncThunk(
  "dashboards/removeDashboard",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(
      `${API_DASHBOARDS_URL}/${id}`
    )
    return response.data
  }
)

export const dashboardByCompany = createAsyncThunk(
  "dashboards/dashboardByCompany",
  async (companyId) => {
    const response = await axios.get(
      `${API_DASHBOARDS_URL}/by/company/${companyId}`
    )
    return response.data
  }
)

export const dashboardByUser = createAsyncThunk(
  "dashboards/dashboardByUser",
  async (userId) => {
    const response = await axios.get(
      `${API_DASHBOARDS_URL}/by/user/${userId}`
    )
    return response.data
  }
)

export const dashboardByPartner = createAsyncThunk(
  "dashboards/dashboardByPartner",
  async (partnerId) => {
    const response = await axios.get(
      `${API_DASHBOARDS_URL}/by/partner/${partnerId}`
    )
    return response.data
  }
)

export const getListByType = createAsyncThunk(
  "dashboards/getListByType",
  async ({
    page = 1,
    limit = 10,
    showAll = true,
    status,
    search,
    noPaginate,
    type
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`   
    if (status) {
      queryParams += `&status=${status}`
    }
    if (search) {
      queryParams += `&search=${search}`
    }
    if (type) {
      queryParams += `&type=${type}`
    }
    if (noPaginate) {
      queryParams += `&noPaginate=${noPaginate}`
    }
    const response = await axios.get(`${API_DASHBOARDS_URL}?${queryParams}`)
    return response.data
  }
)

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default dashboardsSlice.reducer