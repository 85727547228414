// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_FINANCIAL_SUPPORT_LIST_URL, API_FINANCIAL_SUPPORT_REQUESTS_URL } from "../constants"

export const getList = createAsyncThunk(
  "financialSupport/getList",
  async ({
    page = 1,
    limit = 10,
    showAll = true,
    status,
    search
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`   
    if (status) {
      queryParams += `&status=${status}`
    }
    if (search) {
      queryParams += `&search=${search}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_LIST_URL}?${queryParams}`)
    return response.data
  }
)

export const getListRequestsHistoric = createAsyncThunk(
  "financialSupport/getListRequestsHistoric",
  async ({
    page = 1,
    limit = 10,
    showAll = true,
    search,
    manager,
    company,
    executive,
    service,
    includeBuyers,
    year
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (manager) {
      queryParams += `&manager=${manager}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (executive) {
      queryParams += `&executive=${executive}`
    }
    if (service) {
      queryParams += `&service=${service}`
    }
    if (includeBuyers) {
      queryParams += `&includeBuyers=${includeBuyers}`
    }
    if (year) {
      queryParams += `&year=${year}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/historic?${queryParams}`)
    console.log(`RESPONSE getListRequestsHistoric`, response)
    return response.data
  }
)

export const getListRequestsHistoricForBuyers = createAsyncThunk(
  "financialSupport/getListRequestsHistoricForBuyers",
  async ({
    page = 1,
    limit = 10,
    showAll = true,
    search,
    executiveForBuyers
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (executiveForBuyers) {
      queryParams += `&executiveForBuyers=${executiveForBuyers}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/historic?${queryParams}`)
    console.log(`RESPONSE getListRequestsHistoric`, response)
    return response.data
  }
)

export const checkIfExist = createAsyncThunk(
  "financialSupport/checkIfExist",
  async (data) => {
    const response = await axios.get(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/check-if-exist/${data.id}/${data.companyId}`
    )
    return response.data
  }
)

export const createFinancialSupportRequest = createAsyncThunk(
  "financialSupport/createFinancialSupportRequest",
  async (data, { dispatch, getState }) => {
    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/`,
      data
    )
    return response.data
  }
)

export const uploadFiles = createAsyncThunk(
  "financialSupport/uploadFiles",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    formData.append('comments', data.dataToSend.comments)    
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/attachments/${data.dataToSend.financialSupportRequestId}/${data.dataToSend.section}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const editFiles = createAsyncThunk(
  "financialSupport/editFiles",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    let query = ''
    if (data.isOnlyComment) query = '?isOnlyComment=true'
    formData.append('files', data.file[0])
    formData.append('comments', data.dataToSend.comments)
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/edit-file/${data.dataToSend.financialSupportRequestId}/${data.dataToSend.section}${query}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const editFilesForAdmin = createAsyncThunk(
  "financialSupport/editFilesForAdmin",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/edit-file-for-admin/${data.dataToSend.financialSupportRequestId}/${data.dataToSend.section}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const uploadPhotos = createAsyncThunk(
  "financialSupport/uploadPhotos",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/attachments-photos/${data.dataToSend.financialSupportRequestId}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const removePhoto = createAsyncThunk(
  "financialSupport/removePhoto",
  async (data, { dispatch, getState }) => {
    const response = await axios.delete(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/attachments-photos/remove/${data.financialSupportRequestId}/${data.index}`
    )
    return response.data
  }
)

export const updateFinancialSupportRequest = createAsyncThunk(
  "financialSupport/updateFinancialSupportRequest",
  async (data, { dispatch, getState }) => {
    let sectionData = ''
    if (data.section) {
      sectionData = `/${data.section}`
    }
    const response = await axios.put(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/${data._id}${sectionData}`,
      data
    )
    return response.data
  }
)

export const removeToSend = createAsyncThunk(
  "financialSupport/removeToSend",
  async (data, { dispatch, getState }) => {
    const response = await axios.delete(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/remove-to-send/${data.financialSupportRequestId}`
    )
    return response.data
  }
)

export const checkIfExistTemp = createAsyncThunk(
  "financialSupport/checkIfExistTemp",
  async (data) => {
    const response = await axios.get(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/exists/${data.serviceId}/${data.companyId}`
    )
    return response.data
  }
)

export const checkIfExistBuyerTemp = createAsyncThunk(
  "financialSupport/checkIfExistBuyerTemp",
  async (data) => {
    const response = await axios.get(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/exists-for-buyer/${data.type}/${data.serviceId}/${data.buyerId || null}?buyers=${data.buyers}`
    )
    return response.data
  }
)

export const getListRequests = createAsyncThunk(
  "financialSupport/getListRequests",
  async ({
    page = 1,
    limit = 100,
    showAll = true,
    status,
    search,
    company,
    manager,
    statusCommittee,
    statusResults,
    statusInvoice,
    statusPause,
    service,
    executive,
    includeBuyers
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`   
    if (status) {
      queryParams += `&status=${status}`
    }
    if (search) {
      queryParams += `&search=${search}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (manager) {
      queryParams += `&manager=${manager}`
    }
    if (executive) {
      queryParams += `&executive=${executive}`
    }
    if (statusCommittee) {
      queryParams += `&statusCommittee=${statusCommittee}`
    }
    if (statusResults) {
      queryParams += `&statusResults=${statusResults}`
    }
    if (statusInvoice) {
      queryParams += `&statusInvoice=${statusInvoice}`
    }
    if (statusPause) {
      queryParams += `&statusPause=${statusPause}`
    }
    if (service) {
      queryParams += `&service=${service}`
    }
    if (includeBuyers) {
      queryParams += `&includeBuyers=${includeBuyers}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}?${queryParams}`)
    return response.data
  }
)

export const getListRequestsForBuyers = createAsyncThunk(
  "financialSupport/getListRequestsForBuyers",
  async ({
    page = 1,
    limit = 100,
    showAll = true,
    status,
    search,
    statusCommittee,
    statusResults,
    statusInvoice,
    statusPause,
    executiveForBuyers
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`   
    if (status) {
      queryParams += `&status=${status}`
    }
    if (search) {
      queryParams += `&search=${search}`
    }
    if (statusCommittee) {
      queryParams += `&statusCommittee=${statusCommittee}`
    }
    if (statusResults) {
      queryParams += `&statusResults=${statusResults}`
    }
    if (statusInvoice) {
      queryParams += `&statusInvoice=${statusInvoice}`
    }
    if (statusPause) {
      queryParams += `&statusPause=${statusPause}`
    }
    if (executiveForBuyers) {
      queryParams += `&executiveForBuyers=${executiveForBuyers}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}?${queryParams}`)
    return response.data
  }
)

export const getFinancialSupportRequestById = createAsyncThunk(
  "financialSupport/getFinancialSupportRequestById",
  async (id) => {
    const response = await axios.get(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/${id}`
    )
    return response.data
  }
)

export const updateHistorialActivity = createAsyncThunk(
  "financialSupport/updateHistorialActivity",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/activity/${data.id}`,
      data
    )
    return response.data
  }
)

export const getReportCommittee = createAsyncThunk(
  "financialSupport/getReportCommittee",
  async ({
    manager
  } = {}) => {
    let queryParams = ''
    if (manager) {
      queryParams += `?manager=${manager}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/report/committee${queryParams}`)
    return response.data
  }
)

export const getListRequestsToSend = createAsyncThunk(
  "financialSupport/getListRequestsToSend",
  async ({
    page = 1,
    limit = 20,
    showAll = true,
    search,
    company,
    executiveForBuyers
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (executiveForBuyers) {
      queryParams += `&executiveForBuyers=${executiveForBuyers}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/to-send?${queryParams}`)
    return response.data
  }
)

export const getListRequestsCanceled = createAsyncThunk(
  "financialSupport/getListRequestsCanceled",
  async ({
    page = 1,
    limit = 100,
    showAll = true,
    search,
    manager,
    company,
    executive,
    service,
    status,
    canceledType,
    includeBuyers,
    year
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (manager) {
      queryParams += `&manager=${manager}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (executive) {
      queryParams += `&executive=${executive}`
    }
    if (service) {
      queryParams += `&service=${service}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    if (canceledType) {
      queryParams += `&canceledType=${canceledType}`
    }
    if (includeBuyers) {
      queryParams += `&includeBuyers=${includeBuyers}`
    }
    if (year) {
      queryParams += `&year=${year}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/canceled?${queryParams}`)
    return response.data
  }
)

export const getListRequestsCanceledForBuyers = createAsyncThunk(
  "financialSupport/getListRequestsCanceledForBuyers",
  async ({
    page = 1,
    limit = 100,
    showAll = true,
    search,
    status,
    canceledType,
    executiveForBuyers
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    if (canceledType) {
      queryParams += `&canceledType=${canceledType}`
    }
    if (executiveForBuyers) {
      queryParams += `&executiveForBuyers=${executiveForBuyers}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/canceled?${queryParams}`)
    return response.data
  }
)

export const getListRequestsDesisted = createAsyncThunk(
  "financialSupport/getListRequestsDesisted",
  async ({
    page = 1,
    limit = 100,
    showAll = true,
    search,
    manager,
    company,
    executive,
    service,
    includeBuyers,
    year
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (manager) {
      queryParams += `&manager=${manager}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (executive) {
      queryParams += `&executive=${executive}`
    }
    if (service) {
      queryParams += `&service=${service}`
    }
    if (includeBuyers) {
      queryParams += `&includeBuyers=${includeBuyers}`
    }
    if (year) {
      queryParams += `&year=${year}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/desisted?${queryParams}`)
    return response.data
  }
)

export const getListRequestsDesistedForBuyers = createAsyncThunk(
  "financialSupport/getListRequestsDesistedForBuyers",
  async ({
    page = 1,
    limit = 100,
    showAll = true,
    search,
    executiveForBuyers
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (executiveForBuyers) {
      queryParams += `&executiveForBuyers=${executiveForBuyers}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/desisted?${queryParams}`)
    return response.data
  }
)

export const getFinancialSupportRequestReport = createAsyncThunk(
  "financialSupport/getFinancialSupportRequestReport",
  async ({
    page,
    manager
  } = {}) => {
    let queryParams = `currentPage=${page || 1}`
    if (manager) {
      queryParams += `&manager=${manager}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/report/general?${queryParams}`)
    return response.data
  }
)

export const updateObservations = createAsyncThunk(
  "financialSupport/updateObservations",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/observations/${data.id}`,
      data
    )
    return response.data
  }
)

export const sendNotifications = createAsyncThunk(
  "financialSupport/sendNotifications",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/notifications/${data.id}`,
      data
    )
    return response.data
  }
)

export const uploadDesistedDoc = createAsyncThunk(
  "financialSupport/uploadDesistedDoc",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/desisted/document/${data.dataToSend.financialSupportRequestId}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const updateFinancialSupportRequestFormBuyers = createAsyncThunk(
  "financialSupport/updateFinancialSupportRequestFormBuyer",
  async (data, { dispatch, getState }) => {    
    const response = await axios.put(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/form-buyers/${data._id}/executives`,
      data
    )
    return response.data
  }
)

export const updateFinancialSupportRequestBuyerSupports = createAsyncThunk(
  "financialSupport/updateFinancialSupportRequestBuyerSupports",
  async (data, { dispatch, getState }) => {    
    const response = await axios.put(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/buyer-supports/${data._id}/executives`,
      data
    )
    return response.data
  }
)

export const updateFinancialSupportRequestForBuyers = createAsyncThunk(
  "financialSupport/updateFinancialSupportRequestForBuyers",
  async (data, { dispatch, getState }) => {
    let sectionData = ''
    if (data.section) {
      sectionData = `/${data.section}`
    }
    const response = await axios.put(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/request-for-buyer-support/${data._id}${sectionData}`,
      data
    )
    return response.data
  }
)

export const getListForAdmin = createAsyncThunk(
  "financialSupport/getListForAdmin",
  async ({
    page = 1,
    limit = 100,
    showAll = true,
    status,
    search,
    company,
    manager,
    statusCommittee,
    statusResults,
    statusInvoice,
    statusPause,
    service,
    executive,
    includeBuyers
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`   
    if (status) {
      queryParams += `&status=${status}`
    }
    if (search) {
      queryParams += `&search=${search}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (manager) {
      queryParams += `&manager=${manager}`
    }
    if (executive) {
      queryParams += `&executive=${executive}`
    }
    if (statusCommittee) {
      queryParams += `&statusCommittee=${statusCommittee}`
    }
    if (statusResults) {
      queryParams += `&statusResults=${statusResults}`
    }
    if (statusInvoice) {
      queryParams += `&statusInvoice=${statusInvoice}`
    }
    if (statusPause) {
      queryParams += `&statusPause=${statusPause}`
    }
    if (service) {
      queryParams += `&service=${service}`
    }
    if (includeBuyers) {
      queryParams += `&includeBuyers=${includeBuyers}`
    }
    const response = await axios.get(`${API_FINANCIAL_SUPPORT_REQUESTS_URL}/list/admin?${queryParams}`)
    return response.data
  }
)

export const removeFileForAdmin = createAsyncThunk(
  "financialSupport/removeFileForAdmin",
  async (data, { dispatch, getState }) => {
    const response = await axios.post(
      `${API_FINANCIAL_SUPPORT_REQUESTS_URL}/remove-file/${data.id}/${data.section}`,
      data
    )
    return response.data
  }
)

export const financialSupportSlice = createSlice({
  name: "financialSupport",
  initialState: {
    list: [],
    listRequests: [],
    listRequestsForBuyers: [],
    listRequestsHistoric: [],
    listRequestsHistoricForBuyers: [],
    listRequestsToSend: [],
    listRequestsCanceled: [],
    listRequestsCanceledForBuyers: [],
    listRequestsDesisted: [],
    listRequestsDesistedForBuyers: [],
    listForAdmin: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.loading = false
      })
      .addCase(getListRequests.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequests.fulfilled, (state, action) => {
        state.listRequests = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsHistoric.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsHistoric.fulfilled, (state, action) => {
        state.listRequestsHistoric = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsToSend.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsToSend.fulfilled, (state, action) => {
        state.listRequestsToSend = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsCanceled.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsCanceled.fulfilled, (state, action) => {
        state.listRequestsCanceled = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsDesisted.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsDesisted.fulfilled, (state, action) => {
        state.listRequestsDesisted = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsForBuyers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsForBuyers.fulfilled, (state, action) => {
        state.listRequestsForBuyers = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsHistoricForBuyers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsHistoricForBuyers.fulfilled, (state, action) => {
        state.listRequestsHistoricForBuyers = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsCanceledForBuyers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsCanceledForBuyers.fulfilled, (state, action) => {
        state.listRequestsCanceledForBuyers = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListRequestsDesistedForBuyers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListRequestsDesistedForBuyers.fulfilled, (state, action) => {
        state.listRequestsDesistedForBuyers = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
      .addCase(getListForAdmin.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListForAdmin.fulfilled, (state, action) => {
        state.listForAdmin = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export default financialSupportSlice.reducer