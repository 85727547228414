// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_GLOBAL_DATABASE_URL, TOKEN_GLOBAL } from "../constants"

export const getCompaniesGlobalDatabase = createAsyncThunk(
  "tradeMatch/getCompaniesGlobalDatabase",
  async (data, { dispatch, getState }) => {    
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${TOKEN_GLOBAL}`
      }
    }
    const response = await axios.post(
      `${API_GLOBAL_DATABASE_URL}/overview`,
      data,
      axiosConfig
    )
    return response.data
  }
)

export const getCompanyDetails = createAsyncThunk(
  "tradeMatch/getCompanyDetails",
  async ({
    companyId
  } = {}) => {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${TOKEN_GLOBAL}`
      }
    }
    const response = await axios.get(`${API_GLOBAL_DATABASE_URL}/companies/${companyId}`, axiosConfig)
    return response.data
  }
)

export const getEmployeesByCompany = createAsyncThunk(
  "tradeMatch/getEmployeesByCompany",
  async (data, { dispatch, getState }) => {    
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${TOKEN_GLOBAL}`
      }
    }
    const response = await axios.post(
      `${API_GLOBAL_DATABASE_URL}/employees`,
      data,
      axiosConfig
    )
    return response.data
  }
)

export const getEmployeeDetails = createAsyncThunk(
  "tradeMatch/getEmployeeDetails",
  async ({
    employeeId
  } = {}) => {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${TOKEN_GLOBAL}`
      }
    }
    const response = await axios.get(`${API_GLOBAL_DATABASE_URL}/employees/${employeeId}`, axiosConfig)
    return response.data
  }
)

export const tradeMatchSlice = createSlice({
  name: "tradeMatch",
  initialState: {
    list: [],
    totalPages: 1,
    loading: false,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {} 
})

export default tradeMatchSlice.reducer
