// ** React Imports
import { Link } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"

// ** Custom Components
import Avatar from "@components/avatar"
import FormCredentials from "@components/project/form-credentials"

// ** Utils
import { isUserLoggedIn } from "@utils"

// ** Store & Actions
import { getProfileData } from "@src/views/profile/store"
import { useDispatch, useSelector } from "react-redux"
import { handleLogout } from "@store/authentication"

// ** Third Party Components
import { User, Settings, Power } from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap"

// ** Default Avatar Image
import defaultCompanyAvatar from "@src/assets/images/avatars/avatar-default.png"

const UserDropdown = () => {
  // ** States
  const [openModalSettings, setOpenModalSettings] = useState(false)

  // ** Store Vars
  const dispatch = useDispatch()

  const { info: userData } = useSelector((state) => state.profile)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      dispatch(getProfileData())
    }
  }, [])

  //** Vars
  const companyAvatar = (userData && userData.avatar) || defaultCompanyAvatar

  const handleToggleModal = (open) => {
    setOpenModalSettings(open)
  }

  return (
    <>
      {(userData?.typeUser === 'company' || userData?.typeUser === 'partner') ? (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle
            href="/"
            tag="a"
            className="nav-link dropdown-user-link"
            onClick={(e) => e.preventDefault()}
          >
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name fw-bold">
                {(userData?.typeUser === 'company') ? (
                  <Fragment>
                    {(userData?.tradeName.toUpperCase()) || "Empresa"}
                  </Fragment>
                ) : (
                  <Fragment>
                    {(userData?.fiscalName.toUpperCase()) || "Alidado"}
                  </Fragment>
                )}
              </span>
              <span className="user-status">Administrador</span>
            </div>
            <Avatar img={companyAvatar} imgHeight="40" imgWidth="40" />
          </DropdownToggle>
          <DropdownMenu end>

            {(userData?.typeUser === 'company') && (
              <Fragment>
                <DropdownItem tag={Link} to="/profile">
                  <User size={14} className="me-75" />
                  <span className="align-middle">Perfil</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleToggleModal(true)}>
                  <Settings size={14} className="me-75" />
                  <span className="align-middle">Configuraciones</span>
                </DropdownItem>
              </Fragment>
            )}

            <DropdownItem
              tag={Link}
              to="/login"
              onClick={() => dispatch(handleLogout())}
            >
              <Power size={14} className="me-75" />
              <span className="align-middle">Cerrar sesión</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle
            href="/"
            tag="a"
            className="nav-link dropdown-user-link"
            onClick={(e) => e.preventDefault()}
          >
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name fw-bold">
                {(userData?.fullName) || ""}
              </span>
              <span className="user-status">{(userData?.email) || ""}</span>
              {(userData?.businessSectors && userData?.businessSectors.length > 0) ? (
                <span className="user-status" style={{ marginTop: 10 }}>
                  {userData?.businessSectors.map((el) => (`| ${el.name} |`))}
                </span>
              ) : null}
            </div>
            <Avatar
              initials
              color={"light-primary"}
              className="rounded upper"
              content={userData?.fullName}
              contentStyles={{
                borderRadius: 0,
                fontSize: "calc(24px)",
                width: "100%",
                height: "100%"
              }}
              style={{
                height: "45px",
                width: "45px"
              }}
            />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem
              tag={Link}
              to="/login"
              onClick={() => dispatch(handleLogout())}
            >
              <Power size={14} className="me-75" />
              <span className="align-middle">Cerrar sesión</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}

      <Modal
        isOpen={openModalSettings}
        toggle={() => handleToggleModal(false)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => handleToggleModal(false)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 pt-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Configuraciones de la cuenta</h1>
            <p>
              Actualiza tu correo electrónico de recuperación de contraseña y
              tus credenciales de acceso.
            </p>
          </div>
          <FormCredentials
            section="update-credentials"
            userData={userData}
            close={handleToggleModal}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default UserDropdown
