// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_HISTORIES_URL } from "../constants"

export const getListHistories = createAsyncThunk(
  "histories/getList",
  async ({
    page = 1,
    limit = 10,
    showAll = true,
    noPaginate,
    section
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}&showAll=${showAll}`
    if (section) {
      queryParams += `&section=${section}`
    }
    if (noPaginate) {
      queryParams += `&noPaginate=${noPaginate}`
    }
    const response = await axios.get(`${API_HISTORIES_URL}?${queryParams}`)
    return response.data
  }
)

export const storeHistory = createAsyncThunk(
  "histories/storeHistory",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_HISTORIES_URL}`,
      data
    )
    return response.data
  }
)

export const updateHistory = createAsyncThunk(
  "histories/updateHistory",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_HISTORIES_URL}/${data._id}`,
      data
    )
    return response.data
  }
)

export const historiesSlice = createSlice({
  name: "histories",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {}
})

export default historiesSlice.reducer
