// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_BUYER_SUPPORTS_URL } from "../constants"

export const buyerSupportById = createAsyncThunk(
  "buyerSupports/buyerSupportById",
  async (id) => {
    const response = await axios.get(
      `${API_BUYER_SUPPORTS_URL}/${id}`
    )
    return response.data
  }
)

export const storeBuyerSupport = createAsyncThunk(
  "buyerSupports/storeBuyerSupport",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_BUYER_SUPPORTS_URL}`,
      data
    )
    return response.data
  }
)

export const storeMultiBuyerSupport = createAsyncThunk(
  "buyerSupports/storeMultiBuyerSupport",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_BUYER_SUPPORTS_URL}/multi`,
      data
    )
    return response.data
  }
)

export const uploadFiles = createAsyncThunk(
  "buyerSupports/uploadFiles",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    formData.append('comments', data.dataToSend.comments)    
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_BUYER_SUPPORTS_URL}/attachments/${data.dataToSend.buyerSupportId}/${data.dataToSend.section}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const editFiles = createAsyncThunk(
  "buyerSupports/editFiles",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    let query = ''
    if (data.isOnlyComment) query = '?isOnlyComment=true'
    formData.append('files', data.file[0])
    formData.append('comments', data.dataToSend.comments)
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_BUYER_SUPPORTS_URL}/edit-file/${data.dataToSend.buyerSupportId}/${data.dataToSend.section}${query}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const updateObservationsForBuyerSupports = createAsyncThunk(
  "buyerSupports/updateObservationsForBuyerSupports",
  async (data, { dispatch, getState }) => {   
    const response = await axios.post(
      `${API_BUYER_SUPPORTS_URL}/observations/${data.id}`,
      data
    )
    return response.data
  }
)

export const buyerSupportsSlice = createSlice({
  name: "buyerSupports",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0
  },
  reducers: {}
})

export default buyerSupportsSlice.reducer