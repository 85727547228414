// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_INDUSTRIES_URL } from "../constants"

export const getListIndustriesForGto = createAsyncThunk(
  "industries/getListIndustriesForGto",
  async ({
    page = 1,
    limit = 20,
    search,
    businessSector
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (businessSector) {
      queryParams += `&businessSector=${businessSector}`
    }
    const response = await axios.get(`${API_INDUSTRIES_URL}/for/gto-supply?${queryParams}`)
    return response.data
  }
)

export const updateIndustry = createAsyncThunk(
  "industries/updateIndustry",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_INDUSTRIES_URL}/${data._id}`,
      data
    )
    return response.data
  }
)

export const updateImagesIndustries = createAsyncThunk(
  "industries/updateImagesIndustries",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_INDUSTRIES_URL}/image/${data.dataToSend.id}/${data.dataToSend.actionType}/${data.dataToSend.imageType}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const industriesSlice = createSlice({
  name: "industries",
  initialState: {
    listForGto: []
  },
  reducers: {}
})

export default industriesSlice.reducer