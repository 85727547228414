// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt"

// ** Constants
import { API_AUTH_URL, API_LOGINS_URL } from "../constants"

const config = useJwt.jwtConfig

// ** Function return userData from local storage
const initialUser = () => {
  const item = window.localStorage.getItem("userData")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

// ** Requesting function recovery password
export const requestRecoveryPassword = createAsyncThunk(
  "authentication/requestRecoveryPassword",
  async (data) => {
    const response = await axios.post(
      `${API_AUTH_URL}/new-password-request`,
      data
    )
    return response.data
  }
)

// ** Function that validates token from email sent via recovery password
export const validateRecoveryToken = createAsyncThunk(
  "authentication/validateRecoveryToken",
  async (token) => {
    const response = await axios.get(`auth/validate-token${token}`)
    return response.data
  }
)

// ** Function that verify is exists user login data from BD
export const verifyLoginDataUser = createAsyncThunk(
  "authentication/verifyLoginDataUser",
  async (token) => {
    const response = await axios.get(`${API_LOGINS_URL}/user/${token}`)
    return response.data
  }
)

// ** Function that verify is exists company login data from BD
export const verifyLoginDataCompany = createAsyncThunk(
  "authentication/verifyLoginDataCompany",
  async (token) => {
    const response = await axios.get(`${API_LOGINS_URL}/company/${token}`)
    return response.data
  }
)

// ** Function that remove login data from BD
export const removeLoginData = () => {
  const { loginId } = initialUser()
  if (loginId) {
    axios
    .delete(`${API_LOGINS_URL}/${loginId}`)
    .then((response) => {
      if (response) console.log(`LOGIN REMOVED`)
    })
    .catch((error) => console.log(error)) 
  }  
}

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
    recoveryData: {}
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem("userData", JSON.stringify(action.payload))
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken)
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      )
    },
    handleLogout: (state) => {
      removeLoginData()
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData")
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(validateRecoveryToken.fulfilled, (state, action) => {
      // ** Set recoveryData in state
      state.recoveryData = action.payload.data
    })
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
