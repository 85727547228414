// ** Icons Import
import { User, DollarSign, Circle, CheckSquare, BarChart2, Settings, Briefcase, Lock, Folder, Box, Command, Shield, Smile, Globe, RefreshCcw, Target, Activity } from "react-feather"

// ** List routes navigation from sidebar
export default [
  {
    header: "MENÚ DE NAVEGACIÓN"
  },
  {
    id: "statistics",
    title: "Estadísticas",
    icon: <BarChart2 size={20} />,
    navLink: "/statistics",
    action: 'read',
    resource: 'Statistics'
  },  
  {
    id: "profile",
    title: "Perfil",
    icon: <User size={20} />,
    navLink: "/profile",
    action: 'read',
    resource: 'Profile'
  },
  {
    id: 'businessOpportunities',
    title: "Oportunidades",
    icon: <Smile size={12} />,
    navLink: '/business-opportunities',
    action: 'read',
    resource: 'BusinessOpportunities',
    children: [
      {
        id: 'businessOpportunitiesList',
        title: 'Listado',
        icon: <Circle size={12} />,
        navLink: '/business-opportunities/list',
        action: 'read',
        resource: 'BusinessOpportunitiesList'
      },
      {
        id: 'businessOpportunitiesApplications',
        title: 'Postulaciones',
        icon: <Circle size={12} />,
        navLink: '/business-opportunities/applications',
        action: 'read',
        resource: 'BusinessOpportunitiesApplications'
      }
    ]
  },
  {
    id: "financialSupport",
    title: "Apoyos económicos",
    icon: <DollarSign size={20} />,
    action: 'read',
    resource: 'FinancialSupport',
    children: [
      {
        id: 'financialSupportList',
        title: 'Solicitar apoyo',
        icon: <Circle size={12} />,
        navLink: '/financial-support/list',
        action: 'read',
        resource: 'FinancialSupportList'
      },
      {
        id: 'financialSupportToSend',
        title: 'Por enviar',
        icon: <Circle size={12} />,
        navLink: '/financial-support/toSend',
        action: 'read',
        resource: 'FinancialSupportToSend'
      },
      {
        id: 'financialSupportTracing',
        title: 'En seguimiento',
        icon: <Circle size={12} />,
        navLink: '/financial-support/tracing',
        action: 'read',
        resource: 'FinancialSupportTracing'
      },
      {
        id: "financialSupportHistoric",
        title: "Apoyos otorgados",
        icon: <Circle size={12} />,
        navLink: '/financial-support/historic',
        action: 'read',
        resource: 'FinancialSupportHistoric'
      }
    ]
  },  
  {
    id: "services",
    title: "Servicios",
    icon: <Folder size={20} />,
    action: 'read',
    resource: 'Services',
    children: [
      {
        id: 'servicesRequest',
        title: 'Solicitar',
        icon: <Circle size={12} />,
        navLink: '/services/request',
        action: 'read',
        resource: 'ServicesRequest'
      },
      {
        id: 'servicesTracing',
        title: 'Seguimiento',
        icon: <Circle size={12} />,
        navLink: '/services/tracing',
        action: 'read',
        resource: 'ServicesTracing'
      },
      {
        id: 'servicesHistory',
        title: 'Historial',
        icon: <Circle size={12} />,
        navLink: '/services/history',
        action: 'read',
        resource: 'ServicesHistory'
      }
    ]
  },
  {
    id: "statisticsUser",
    title: "Estadísticas",
    icon: <BarChart2 size={20} />,
    navLink: "/user/statistics",
    action: 'read',
    resource: 'StatisticsUser'
  },
  {
    id: "companies",
    title: "Empresas",
    icon: <Briefcase size={20} />,
    navLink: "/user/companies",
    action: 'read',
    resource: 'Companies'
  },
  {
    id: "financialSupportUser",
    title: "Apoyos empresas",
    icon: <DollarSign size={20} />,
    action: 'read',
    resource: 'FinancialSupportUser',
    children: [
      {
        id: 'financialSupportTracingUser',
        title: 'Seguimiento',
        icon: <Circle size={12} />,
        navLink: '/user/financial-support/tracing',
        action: 'read',
        resource: 'FinancialSupportTracingUser'
      },
      {
        id: 'financialSupportHistoricUser',
        title: "Apoyos otorgados",
        icon: <Circle size={12} />,
        navLink: '/user/financial-support/historic',
        action: 'read',
        resource: 'FinancialSupportHistoricUser'
      },
      {
        id: 'financialSupportCanceledUser',
        title: "Apoyos cancelados",
        icon: <Circle size={12} />,
        navLink: '/user/financial-support/canceled',
        action: 'read',
        resource: 'FinancialSupportCanceledUser'
      },
      {
        id: 'financialSupportDesistedUser',
        title: "Apoyos desistidos",
        icon: <Circle size={12} />,
        navLink: '/user/financial-support/desisted',
        action: 'read',
        resource: 'FinancialSupportCanceledUser'
      }
    ]
  },
  {
    id: "financialSupportAdmin",
    title: "Apoyos económicos",
    icon: <DollarSign size={20} />,
    action: 'read',
    resource: 'FinancialSupportAdmin',
    children: [
      {
        id: 'financialSupportTracingAdmin',
        title: 'Seguimiento',
        icon: <Circle size={12} />,
        navLink: '/admin/financial-support/tracing',
        action: 'read',
        resource: 'FinancialSupportTracingAdmin'
      },
      {
        id: 'financialSupportHistoricAdmin',
        title: "Apoyos otorgados",
        icon: <Circle size={12} />,
        navLink: '/admin/financial-support/historic',
        action: 'read',
        resource: 'FinancialSupportHistoricAdmin'
      },
      {
        id: 'financialSupportCanceledAdmin',
        title: "Apoyos cancelados",
        icon: <Circle size={12} />,
        navLink: '/admin/financial-support/canceled',
        action: 'read',
        resource: 'FinancialSupportCanceledAdmin'
      },
      {
        id: 'financialSupportDesistedAdmin',
        title: "Apoyos desistidos",
        icon: <Circle size={12} />,
        navLink: '/admin/financial-support/desisted',
        action: 'read',
        resource: 'FinancialSupportDesistedAdmin'
      }
    ]
  },
  {
    id: "buyers",
    title: "Apoyos compradores",
    icon: <DollarSign size={20} />,
    action: 'read',
    resource: 'Buyers',
    children: [
      {
        id: 'financialSupportListBuyers',
        title: 'Solicitar apoyo',
        icon: <Circle size={12} />,
        navLink: '/user/buyers/financial-support/list',
        action: 'read',
        resource: 'FinancialSupportListBuyers'
      },
      {
        id: 'financialSupportToSendBuyers',
        title: 'Por enviar',
        icon: <Circle size={12} />,
        navLink: '/user/buyers/financial-support/toSend',
        action: 'read',
        resource: 'FinancialSupportToSendBuyers'
      },
      {
        id: 'financialSupportTracingBuyers',
        title: 'Seguimiento',
        icon: <Circle size={12} />,
        navLink: '/user/buyers/financial-support/tracing',
        action: 'read',
        resource: 'FinancialSupportTracingBuyers'
      },
      {
        id: 'financialSupportHistoricBuyers',
        title: 'Apoyos otorgados',
        icon: <Circle size={12} />,
        navLink: '/user/buyers/financial-support/historic',
        action: 'read',
        resource: 'FinancialSupportHistoricBuyers'
      },
      {
        id: 'financialSupportCanceledBuyers',
        title: 'Apoyos cancelados',
        icon: <Circle size={12} />,
        navLink: '/user/buyers/financial-support/canceled',
        action: 'read',
        resource: 'FinancialSupportCanceledBuyers'
      },
      {
        id: 'financialSupportDesistedBuyers',
        title: 'Apoyos desistidos',
        icon: <Circle size={12} />,
        navLink: '/user/buyers/financial-support/desisted',
        action: 'read',
        resource: 'FinancialSupportDesistedBuyers'
      }
    ]
  },
  {
    id: "archivenomy",
    title: "Archivonomía",
    icon: <Box size={20} />,
    navLink: "/archivenomy",
    action: 'read',
    resource: 'Archivenomy'
  },
  {
    id: "settings",
    title: "Configuraciones",
    icon: <Settings size={20} />,
    action: 'read',
    resource: 'Settings',
    children: [
      {
        id: 'settingsStatistics',
        title: 'Tableros',
        icon: <BarChart2 size={20} />,
        action: 'read',
        resource: 'SettingsStatistics',
        children: [
          {
            id: 'settingsStatisticsCompanies',
            title: 'Empresas',
            icon: <Circle size={12} />,
            navLink: '/superadmin/settings/statistics/companies',
            action: 'read',
            resource: 'SettingsStatisticsCompanies'
          },
          {
            id: 'settingsStatisticsPartners',
            title: 'Aliados',
            icon: <Circle size={12} />,
            navLink: '/superadmin/settings/statistics/partners',
            action: 'read',
            resource: 'SettingsStatisticsPartners'
          },
          {
            id: 'settingsStatisticsTradematch',
            title: 'Tradematch',
            icon: <Circle size={12} />,
            navLink: '/superadmin/settings/statistics/tradematch',
            action: 'read',
            resource: 'SettingsStatisticsPartners'
          }
        ]
      },
      {
        id: 'settingsCredentials',
        title: "Contraseñas",
        icon: <Lock size={12} />,
        navLink: '/superadmin/settings/credentials',
        action: 'read',
        resource: 'SettingsCredentials',
        children: [
          {
            id: 'settingsCredentialsCompanies',
            title: 'Empresas',
            icon: <Circle size={12} />,
            navLink: '/superadmin/settings/credentials/companies',
            action: 'read',
            resource: 'SettingsCredentialsCompanies'
          },
          {
            id: 'settingsCredentialsPartners',
            title: 'Aliados',
            icon: <Circle size={12} />,
            navLink: '/superadmin/settings/credentials/partners',
            action: 'read',
            resource: 'SettingsCredentialsPartners'
          }
        ]
      },
      {
        id: "settingsBusinessSectors",
        title: "Sectores / Ramas",
        icon: <Command size={20} />,
        navLink: '/superadmin/settings/business-sectors',
        action: 'read',
        resource: 'SettingsBusinessSectors'
      },
      {
        id: "benefitsSync",
        title: "Sincronizar benefits",
        icon: <RefreshCcw size={20} />,
        navLink: '/superadmin/settings/sync-benefits',
        action: 'read',
        resource: 'SettingsBusinessSectors'
      },
      {
        id: "managmentSupports",
        title: "Gestionar apoyos",
        icon: <DollarSign size={20} />,
        navLink: '/superadmin/settings/supports',
        action: 'read',
        resource: 'SettingsBusinessSectors'
      }
      /*{
        id: "settingsRoles",
        title: "Roles",
        icon: <Shield size={20} />,
        navLink: '/superadmin/settings/roles',
        action: 'read',
        resource: 'settingsRoles'
      }*/
    ]
  },
  {
    id: "statisticsPartner",
    title: "Estadísticas",
    icon: <BarChart2 size={20} />,
    navLink: "/partner/statistics",
    action: 'read',
    resource: 'StatisticsPartner'
  },
  {
    id: "tradematch",
    title: "Tradematch",
    icon: <Target size={20} />,
    navLink: "/tradematch",
    action: 'read',
    resource: 'TradematchUser'
  },
  {
    id: "exportPulseUser",
    title: "Empresocardiogra..",
    icon: <Activity size={20} />,
    navLink: "/user/exportpulse",
    action: 'read',
    resource: 'ExportPulseUser'
  },
  {
    id: "sites",
    title: "Otros sitios",
    icon: <Globe size={20} />,
    action: 'read',
    resource: 'Companies',
    children: [
      {
        id: 'gtosupply',
        title: 'Ir a Gto Supply',
        icon: <Circle size={12} />,
        externalLink: true,
        navLink: 'https://gtosupply.com/agents/',
        action: 'read',
        resource: 'Companies',
        newTab: true
      },
      {
        id: 'smart',
        title: 'Ir a SMART',
        icon: <Circle size={12} />,
        externalLink: true,
        navLink: 'https://smart.cofoce.gob.mx/',
        action: 'read',
        resource: 'Companies',
        newTab: true
      }
    ]
  }
]
